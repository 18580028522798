*:focus-visible {
    outline-color: var(--color--primary);
    outline-offset: 0.125rem;
    background-color: rgba(var(--color--primary-rgb), 0.1);
}

.sr-only {
    position: absolute !important;
    overflow: hidden !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    white-space: nowrap !important;
    border: 0 !important;
    clip-path: inset(50%) !important;
}

.sr-only-focusable:focus {
    overflow: visible !important;
    clip: auto !important;
    width: auto !important;
    height: auto !important;
    margin: auto !important;
    white-space: normal !important;
    clip-path: none !important;
}
