@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url("base/icon.css");
@import url("base/variables.css");
@import url("base/normalize.css");
@import url("base/accessibility.css");

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

html  {
  background-color: var(--color--grey-xlight);
  scroll-behavior: smooth;
  color: var(--color--primary-dark);
  /*scroll-padding: calc(var(--header-height) + 4rem);*/

  &.-filter-open,
  &.-menu-open {
    header {
      .month {
        @media screen and (max-width: 1023px) {
          bottom: -8rem;
        }
      }
    }
  }
}

* {
  font-family: "Raleway", sans-serif;
}

a {
  color: var(--color--primary-dark);

  &:hover,
  &:focus-visible {
    color: var(--color--primary);
  }
}

main {
  position: relative;
  padding: calc(var(--header-height) + 3rem) 1rem 0;

  @media screen and (min-width: 1024px) {
    padding: calc(var(--header-height) + 4.5rem) 2rem 0;
  }
}

.header {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 20;
  width: calc(100% - 2rem);
  height: var(--header-height);
  background-color: var(--color--white);
  display: grid;
  grid-template-columns: minmax(0, var(--header-height)) minmax(0, 1fr) minmax(0, var(--header-height));
  align-items: center;
  transition: var(--transition-slow);
  box-shadow: var(--shadow);
  border-radius: 0.75rem;

  .header__logo {
    padding: 0.75rem;

    .header__logo__svg {
      &.-mobile {
        display: none;
        border-radius: 0.5rem;

        @media screen and (max-width: 1023px) {
          display: block;
        }
      }
      
      &.-desktop {
        display: block;
        border-radius: 0.5rem;

        path {
          transition: var(--transition);
        }

        @media screen and (max-width: 1023px) {
          display: none;
        }
      }
    }

    @media screen and (min-width: 1024px) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0;

      &:hover,
      &:focus-visible {
        path {
          fill: color-mix(in srgb, var(--color--primary), black 30%);
        }
      }
    }
  }

  .header__nav,
  .header__filter {
    position: relative;
    height: 100%;

    .header__action {
      display: flex;
      flex-direction: column;
      gap: 0.375rem;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
      cursor: pointer;
      transition: var(--transition);

      span {
        font-size: 0.625rem;
        font-weight: 600;
        text-transform: uppercase;
        color: var(--color--grey-dark);
      }

      i {
        font-size: 1.5rem;
        color: var(--color--grey-dark);
        transition: var(--transition);
      }

      &[disabled] {
        user-select: none;
        pointer-events: none;
        user-focus: none;
        cursor: default;
        opacity: 0.5;
      }
      
      @media screen and (min-width: 1024px) {
        display: none;
      }
    }

    &.-active {
      .header__action {
        i {
          color: var(--color--primary);
        }

        border-color: var(--color--primary);
      }

      .filter__indicator {
        opacity: 0;
      }
    }
  }

  &.-countries {
    grid-template-columns: minmax(0, var(--header-height)) minmax(0, 1fr) minmax(0, var(--header-height));

    @media screen and (min-width: 1024px) {
      grid-template-columns: minmax(0, 8rem) minmax(0, 1fr) minmax(0, var(--header-height));
    }
  }

  &.-hidden {
    top: calc((var(--header-height) * -1) - 2rem);

    & + .nav-menu {
      @media screen and (min-width: 1024px) {
        top: calc((var(--header-height) * -1) - 2rem);
      }
    }
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: minmax(0, 8rem) minmax(0, 1fr) minmax(0, var(--header-height));
    padding-left: 0.75rem;
    padding-right: 0.5rem;
    gap: 0.5rem;
    left: 2rem;
    width: calc(100% - 4rem);
  }
}

.rte {
  font-family: "Noto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

  strong {
    color: var(--color--primary-dark);
    font-weight: 600;
  }
}

.nav-menu {
  display: flex;
  position: fixed;
  z-index: 99;
  transition: var(--transition-slow);
  font-weight: 500;

  .nav-menu__title {
    margin: 0;
  }

  .nav-menu__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    padding: 0;
    list-style: none;
    margin: 0 0 0 -0.75rem;

    a {
      display: flex;
      text-decoration: none;
      align-items: center;
      padding: 0.5rem 0.75rem;
      position: relative;
      transition: 0.45s cubic-bezier(0.25,1,0.33,1);
      font-size: 1.125rem;
      gap: 0.75rem;
      font-weight: 600;

      i {
        font-size: 1rem;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: color-mix(in srgb, var(--color--primary), var(--color--white) 80%);
        z-index: -1;
        border-radius: 0.25rem;
        transform: scale(0.5);
        opacity: 0;
        transition: 0.45s cubic-bezier(0.25,1,0.33,1);
      }

      &:hover,
      &:focus-visible,
      &.-active {
        &::before {
          opacity: 1;
          transform: scale(1);
        }

        color: var(--color--primary);
      }

      &.-countries {
        --color--primary: var(--color--global-70);
      }

      &.-blog {
        --color--primary: var(--color--hiking-70);
      }

      &.-contact {
        --color--primary: var(--color--surf-80);
      }

      @media screen and (min-width: 1024px) {
        font-size: 1rem;
      }
    }

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      gap: 1rem;
    }
  }

  &.-active {
    right: 0;
    visibility: visible;
  }

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
    width: 100%;
    max-width: 20rem;
    height: calc(100dvh - calc(var(--header-height) * 4));
    top: 50%;
    transform: translateY(-50%);
    right: -20rem;
    visibility: hidden;
    box-shadow: var(--shadow-medium);
    background-color: var(--color--white);
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }


  @media screen and (min-width: 1024px) {
    top: 1rem;
    right: 4rem;
    height: var(--header-height);
  }
}

.overlay-close {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0,0,0, 0.1) 0%, rgba(0,0,0, 0.25) 100%);
  opacity: 0;
  visibility: hidden;
  border: none;
  transition: var(--transition-slow);

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.search-full {
  position: fixed;
  top: -3rem;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: var(--color--grey-xlight);
  border-bottom: 0.0625rem solid var(--color--grey-light);
  padding: 0.75rem 0;
  box-shadow: var(--shadow-small);
  visibility: hidden;
  transition: var(--transition-slow);

  .search-container {
    input {
      background-color: var(--color--white);
      border: 0.0625rem solid var(--color--grey-light);
    }

    > .autocomplete-list {
      top: calc(100% + 0.75rem);

      @media screen and (max-width: 1023px) {
        border-radius: 0;
      }

      @media screen and (min-width: 1024px) {
        top: unset;
        bottom: calc(100% + 1rem);
      }
    }
  }

  @media screen and (min-width: 1024px) {
    top: unset;
    bottom: -2rem;
    border-top: 0.0625rem solid var(--color--grey-light);
    border-bottom: none;
    padding: 1rem 0;
  }
}

.search-container {
  position: relative;
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  padding: 0 1rem;

  .icon-search {
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.25rem;
    color: var(--color--grey-medium);
    user-select: none;
    pointer-events: none;

    @media screen and (min-width: 1024px) {
      font-size: 1.75rem;
      right: 2rem;
    }
  }

  > input {
    width: 100%;
    padding: 0.75rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    border: none;
    background-color: var(--color--grey-xlight);

    &::placeholder {
      font-weight: 500;
      color: var(--color--grey-medium);
    }

    @media screen and (min-width: 1024px) {
      font-size: 1rem;
      padding: 1.125rem 1.5rem;
    }
  }

  > .autocomplete-list {
    display: none;
    top: calc(100% + 1rem);
    position: absolute;
    left: 0;
    background-color: var(--color--white);
    box-shadow: var(--shadow-small);
    border-radius: 0.25rem;
    width: 100%;
    overflow: auto;
    max-height: 20rem;

    &:empty {
      display: none;
    }

    &.active {
      display: block;
    }

    &.-hidden {
      display: none;
    }

    .autocomplete-item {
      padding: 1rem;
      border-bottom: 0.05rem solid var(--color--grey-xlight);
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      transition: var(--transition);
      cursor: pointer;
      text-decoration: none;

      span {
        font-weight: 400;
        transition: var(--transition);
        color: var(--color--grey);

        &.-highlight {
          color: var(--color--primary);
          font-weight: 700;
        }
      }

      img {
        width: 2rem;
      }

      &:hover,
      &:focus-visible {
        background-color: var(--color--foreground);

        span {
          color: var(--color--primary-dark);
        }
      }
    }

    @media screen and (min-width: 1024px) {
      top: unset;
      bottom: calc(100% + 1rem);
    }
  }
}

html.-search-active {
  .search-full {
    visibility: visible;

    @media screen and (max-width: 1023px) {
      top: var(--header-height);
    }

    @media screen and (min-width: 1024px) {
      bottom: var(--header-height);
    }
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  border-radius: 5px;
}

