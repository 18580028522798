@font-face {
  font-family: 'whentoexplore';
  src:
    url('../fonts/whentoexplore.ttf?3ipq8') format('truetype'),
    url('../fonts/whentoexplore.woff?3ipq8') format('woff'),
    url('../fonts/whentoexplore.svg?3ipq8#whentoexplore') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'whentoexplore' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-stars:before {
  content: "\e9d0";
}
.icon-plug-a:before {
  content: "\e9c4";
}
.icon-plug-b:before {
  content: "\e9c5";
}
.icon-plug-c:before {
  content: "\e9c6";
}
.icon-plug-d:before {
  content: "\e9c7";
}
.icon-plug-m:before {
  content: "\e9c7";
}
.icon-plug-e:before {
  content: "\e9c8";
}
.icon-plug-f:before {
  content: "\e9c9";
}
.icon-plug-l:before {
  content: "\e9ca";
}
.icon-plug-k:before {
  content: "\e9cb";
}
.icon-plug-j:before {
  content: "\e9cc";
}
.icon-plug-n:before {
  content: "\e9cc";
}
.icon-plug-i:before {
  content: "\e9cd";
}
.icon-plug-h:before {
  content: "\e9ce";
}
.icon-plug-g:before {
  content: "\e9cf";
}
.icon-translate:before {
  content: "\e9c2";
}
.icon-g_translate:before {
  content: "\e9c3";
}
.icon-add:before {
  content: "\e9b4";
}
.icon-remove:before {
  content: "\e9b5";
}
.icon-right_panel_open:before {
  content: "\e9b6";
}
.icon-left_panel_open:before {
  content: "\e9b7";
}
.icon-right_panel_close:before {
  content: "\e9b8";
}
.icon-expansion_panels:before {
  content: "\e9b9";
}
.icon-settings_overscan:before {
  content: "\e9ba";
}
.icon-arrows_outward:before {
  content: "\e9bb";
}
.icon-open_in_full:before {
  content: "\e9bc";
}
.icon-pan_zoom:before {
  content: "\e9bd";
}
.icon-expand_content:before {
  content: "\e9be";
}
.icon-info:before {
  content: "\e9bf";
}
.icon-question_mark:before {
  content: "\e9c0";
}
.icon-help:before {
  content: "\e9c1";
}
.icon-savings:before {
  content: "\e9a9";
}
.icon-euro:before {
  content: "\e9aa";
}
.icon-account_balance:before {
  content: "\e9ab";
}
.icon-currency:before {
  content: "\e9ac";
}
.icon-account_balance_wallet:before {
  content: "\e9ad";
}
.icon-payments:before {
  content: "\e9ae";
}
.icon-warning:before {
  content: "\e9af";
}
.icon-error:before {
  content: "\e9b0";
}
.icon-priority_high:before {
  content: "\e9b1";
}
.icon-report:before {
  content: "\e9b2";
}
.icon-new_releases:before {
  content: "\e9b3";
}
.icon-local_drink:before {
  content: "\e9a3";
}
.icon-water_drop:before {
  content: "\e9a4";
}
.icon-waves:before {
  content: "\e9a5";
}
.icon-water:before {
  content: "\e9a6";
}
.icon-pool:before {
  content: "\e9a7";
}
.icon-socket:before {
  content: "\e9a8";
}
.icon-description:before {
  content: "\e99a";
}
.icon-demography:before {
  content: "\e99b";
}
.icon-car:before {
  content: "\e99c";
}
.icon-train:before {
  content: "\e99d";
}
.icon-bike:before {
  content: "\e99e";
}
.icon-boat:before {
  content: "\e99f";
}
.icon-syringe:before {
  content: "\e9a0";
}
.icon-emergency:before {
  content: "\e9a1";
}
.icon-encrypted:before {
  content: "\e9a2";
}
.icon-map1:before {
  content: "\e990";
}
.icon-globe-search:before {
  content: "\e991";
}
.icon-globe1:before {
  content: "\e992";
}
.icon-filter:before {
  content: "\e993";
}
.icon-apps:before {
  content: "\e994";
}
.icon-more:before {
  content: "\e995";
}
.icon-close:before {
  content: "\e996";
}
.icon-settings1:before {
  content: "\e997";
}
.icon-menu:before {
  content: "\e998";
}
.icon-search:before {
  content: "\e999";
}
.icon-cold:before {
  content: "\e98d";
}
.icon-hot:before {
  content: "\e98e";
}
.icon-hot-cold:before {
  content: "\e98f";
}
.icon-beach:before {
  content: "\e98b";
}
.icon-hiking:before {
  content: "\e98c";
}
.icon-surf:before {
  content: "\e988";
}
.icon-scuba:before {
  content: "\e989";
}
.icon-ski:before {
  content: "\e98a";
}
.icon-snowy:before {
  content: "\e982";
}
.icon-cloud1:before {
  content: "\e983";
}
.icon-cloud-hail:before {
  content: "\e984";
}
.icon-rainy:before {
  content: "\e985";
}
.icon-thunderstorm:before {
  content: "\e986";
}
.icon-cloudy:before {
  content: "\e987";
}
.icon-dollar-sign-3:before {
  content: "\e97e";
}
.icon-dollar-sign-2:before {
  content: "\e97f";
}
.icon-droplet-3:before {
  content: "\e980";
}
.icon-droplet-2:before {
  content: "\e981";
}
.icon-alert-circle:before {
  content: "\e900";
}
.icon-alert-octagon:before {
  content: "\e901";
}
.icon-alert-triangle:before {
  content: "\e902";
}
.icon-arrow-down:before {
  content: "\e903";
}
.icon-arrow-down-circle:before {
  content: "\e904";
}
.icon-arrow-down-left:before {
  content: "\e905";
}
.icon-arrow-down-right:before {
  content: "\e906";
}
.icon-arrow-left:before {
  content: "\e907";
}
.icon-arrow-left-circle:before {
  content: "\e908";
}
.icon-arrow-right:before {
  content: "\e909";
}
.icon-arrow-right-circle:before {
  content: "\e90a";
}
.icon-arrow-up:before {
  content: "\e90b";
}
.icon-arrow-up-circle:before {
  content: "\e90c";
}
.icon-arrow-up-left:before {
  content: "\e90d";
}
.icon-arrow-up-right:before {
  content: "\e90e";
}
.icon-award:before {
  content: "\e90f";
}
.icon-bar-chart:before {
  content: "\e910";
}
.icon-bar-chart-2:before {
  content: "\e911";
}
.icon-bell:before {
  content: "\e912";
}
.icon-bell-off:before {
  content: "\e913";
}
.icon-bookmark:before {
  content: "\e914";
}
.icon-camera:before {
  content: "\e915";
}
.icon-camera-off:before {
  content: "\e916";
}
.icon-check:before {
  content: "\e917";
}
.icon-check-circle:before {
  content: "\e918";
}
.icon-check-square:before {
  content: "\e919";
}
.icon-chevron-down:before {
  content: "\e91a";
}
.icon-chevron-left:before {
  content: "\e91b";
}
.icon-chevron-right:before {
  content: "\e91c";
}
.icon-chevron-up:before {
  content: "\e91d";
}
.icon-chevrons-down:before {
  content: "\e91e";
}
.icon-chevrons-left:before {
  content: "\e91f";
}
.icon-chevrons-right:before {
  content: "\e920";
}
.icon-chevrons-up:before {
  content: "\e921";
}
.icon-circle:before {
  content: "\e922";
}
.icon-clipboard:before {
  content: "\e923";
}
.icon-clock:before {
  content: "\e924";
}
.icon-cloud:before {
  content: "\e925";
}
.icon-cloud-drizzle:before {
  content: "\e926";
}
.icon-cloud-lightning:before {
  content: "\e927";
}
.icon-cloud-off:before {
  content: "\e928";
}
.icon-cloud-rain:before {
  content: "\e929";
}
.icon-cloud-snow:before {
  content: "\e92a";
}
.icon-columns:before {
  content: "\e92b";
}
.icon-command:before {
  content: "\e92c";
}
.icon-compass:before {
  content: "\e92d";
}
.icon-copy:before {
  content: "\e92e";
}
.icon-crosshair:before {
  content: "\e92f";
}
.icon-dollar-sign:before {
  content: "\e930";
}
.icon-droplet:before {
  content: "\e931";
}
.icon-framer:before {
  content: "\e932";
}
.icon-frown:before {
  content: "\e933";
}
.icon-git-commit:before {
  content: "\e934";
}
.icon-globe:before {
  content: "\e935";
}
.icon-hash:before {
  content: "\e936";
}
.icon-hexagon:before {
  content: "\e937";
}
.icon-home:before {
  content: "\e938";
}
.icon-image:before {
  content: "\e939";
}
.icon-instagram:before {
  content: "\e93a";
}
.icon-layers:before {
  content: "\e93b";
}
.icon-layout:before {
  content: "\e93c";
}
.icon-link:before {
  content: "\e93d";
}
.icon-link-2:before {
  content: "\e93e";
}
.icon-loader:before {
  content: "\e93f";
}
.icon-lock:before {
  content: "\e940";
}
.icon-map:before {
  content: "\e941";
}
.icon-map-pin:before {
  content: "\e942";
}
.icon-meh:before {
  content: "\e943";
}
.icon-message-circle:before {
  content: "\e944";
}
.icon-moon:before {
  content: "\e945";
}
.icon-more-horizontal:before {
  content: "\e946";
}
.icon-more-vertical:before {
  content: "\e947";
}
.icon-move:before {
  content: "\e948";
}
.icon-navigation:before {
  content: "\e949";
}
.icon-navigation-2:before {
  content: "\e94a";
}
.icon-plus:before {
  content: "\e94b";
}
.icon-plus-circle:before {
  content: "\e94c";
}
.icon-plus-square:before {
  content: "\e94d";
}
.icon-send:before {
  content: "\e94e";
}
.icon-settings:before {
  content: "\e94f";
}
.icon-share-2:before {
  content: "\e950";
}
.icon-shield:before {
  content: "\e951";
}
.icon-sliders:before {
  content: "\e952";
}
.icon-smartphone:before {
  content: "\e953";
}
.icon-smile:before {
  content: "\e954";
}
.icon-speaker:before {
  content: "\e955";
}
.icon-star:before {
  content: "\e956";
}
.icon-stop-circle:before {
  content: "\e957";
}
.icon-sun:before {
  content: "\e958";
}
.icon-sunrise:before {
  content: "\e959";
}
.icon-sunset:before {
  content: "\e95a";
}
.icon-tablet:before {
  content: "\e95b";
}
.icon-tag:before {
  content: "\e95c";
}
.icon-target:before {
  content: "\e95d";
}
.icon-thermometer:before {
  content: "\e95e";
}
.icon-thumbs-down:before {
  content: "\e95f";
}
.icon-thumbs-up:before {
  content: "\e960";
}
.icon-tool:before {
  content: "\e961";
}
.icon-trash:before {
  content: "\e962";
}
.icon-trash-2:before {
  content: "\e963";
}
.icon-trending-down:before {
  content: "\e964";
}
.icon-trending-up:before {
  content: "\e965";
}
.icon-triangle:before {
  content: "\e966";
}
.icon-twitter:before {
  content: "\e967";
}
.icon-type:before {
  content: "\e968";
}
.icon-umbrella:before {
  content: "\e969";
}
.icon-unlock:before {
  content: "\e96a";
}
.icon-user:before {
  content: "\e96b";
}
.icon-user-check:before {
  content: "\e96c";
}
.icon-user-minus:before {
  content: "\e96d";
}
.icon-user-plus:before {
  content: "\e96e";
}
.icon-user-x:before {
  content: "\e96f";
}
.icon-users:before {
  content: "\e970";
}
.icon-watch:before {
  content: "\e971";
}
.icon-wifi:before {
  content: "\e972";
}
.icon-wifi-off:before {
  content: "\e973";
}
.icon-wind:before {
  content: "\e974";
}
.icon-x:before {
  content: "\e975";
}
.icon-x-circle:before {
  content: "\e976";
}
.icon-x-octagon:before {
  content: "\e977";
}
.icon-x-square:before {
  content: "\e978";
}
.icon-youtube:before {
  content: "\e979";
}
.icon-zap:before {
  content: "\e97a";
}
.icon-zap-off:before {
  content: "\e97b";
}
.icon-zoom-in:before {
  content: "\e97c";
}
.icon-zoom-out:before {
  content: "\e97d";
}
