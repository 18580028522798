:root {
    --color--primary: #009392;
    --color--primary-rgb : 0, 147, 146;
    --color--primary-dark: #02161e;
    --color--foreground: #f7f7f7;
    --color--map-fg: #78B8CC;
    --color--grey-xlight: #F5F5F5;
    --color--grey-light: #EBEBEB;
    --color--grey-medium: #BDBDBD;
    --color--grey: #646464;
    --color--grey-dark: #344054;
    --color--white: #ffffff;
    --color--weather: #FFAF36;
    --color--tourism: #FF671B;
    --color--price: #1B145D;
    --color--rain: #15aab0;
    --color--red: #ef3b25;
    --color--disabled: #d5d5d5;
    --color--global-95: #037946;
    --color--global-90: #4aaf66;
    --color--global-85: #9ccb86;
    --color--global-80: #CCDB9A;
    --color--global-75: #eeb479;
    --color--global-70: #FA7F5E;
    --color--global-60: #DE4968;
    --color--global-0: #A8327D;
    --color--ski-95: #1A1662;
    --color--ski-90: #1C3B77;
    --color--ski-85: #215D8B;
    --color--ski-80: #2880A0;
    --color--ski-75: #58B4B9;
    --color--ski-70: #85D5CA;
    --color--ski-60: #C9F1E4;
    --color--ski-0: var(--color--disabled);
    --color--beach-95: #721F81;
    --color--beach-90: #A8327D;
    --color--beach-85: #C43C75;
    --color--beach-80: #F1605D;
    --color--beach-75: #FA7F5E;
    --color--beach-70: #FE9F6D;
    --color--beach-60: #FDDEA0;
    --color--beach-0: var(--color--disabled);
    --color--surf-95: #551672;
    --color--surf-90: #701C7A;
    --color--surf-85: #A12A8B;
    --color--surf-80: #D03EA0;
    --color--surf-75: #ED67B4;
    --color--surf-70: #F898C4;
    --color--surf-60: #FFC6D2;
    --color--surf-0: var(--color--disabled);
    --color--hiking-95: #0E4D41;
    --color--hiking-90: #155B45;
    --color--hiking-85: #28794C;
    --color--hiking-80: #3B9752;
    --color--hiking-75: #62B55E;
    --color--hiking-70: #8FD16C;
    --color--hiking-60: #C5EA99;
    --color--hiking-0: var(--color--disabled);
    --transition: 0.15s;
    --transition-slow: 0.3s;
    --shadow: 0.5rem 0.5rem 2rem rgba(112, 144, 176, 0.12);
    --shadow-medium: 0 0 2rem rgba(112, 144, 176, 0.2);
    --shadow-small: 0.25rem 0.25rem 1rem rgba(112, 144, 176, 0.25);
    --header-height: 4rem;

    &[data-theme="dark"] {
        --color--primary-dark: #dcd8e9;
        --color--foreground: #181b2c;
        --color--grey-xlight: #151927;
        --color--grey-light: #1c1f2f;
        --color--grey-medium: #524d63;
        --color--grey: #716d80;
        --color--grey-dark: #b2abd0;
        --color--white: #0b0e17;
        --ss-bg-color: var(--color--white);
        --shadow: 0.5rem 0.5rem 2rem rgba(40, 27, 72, 0.12);
        --shadow-medium: 0 0 2rem rgba(40, 27, 72, 0.12);
        --shadow-small: 0.25rem 0.25rem 1rem rgba(40, 27, 72, 0.12);

        #map {
            background: radial-gradient( #403b6d 0%, var(--color--white) 100%);
        }

        .noUi-handle {
            background: var(--color--grey-light);
            outline: 0.0625rem solid var(--color--primary);
        }
    }

    @media screen and (min-width: 1024px) {
        --header-height: 4.5rem;
    }
}
